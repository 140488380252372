<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0 hGoBack">
        <span @click="$router.go(-1)">
          <b-button
            variant="white"
            class="btn-icon rounded-circle mr-50 text-danger bg-white"
            style="padding: 0.5px"
          >
            <feather-icon
              icon="ArrowLeftCircleIcon"
              size="35"
            />
          </b-button>
        </span>
        {{ $t('cliente_file') }}
      </h2>
    </div>
    <b-card>
      <!-- form -->
      <validation-observer ref="editClient">
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit"
          @keydown.enter="handleSubmit"
        >
          <b-row>
            <b-col sm="6">            
              <b-form-group :label="'Code'" label-for="client-code">
                <b-form-input v-model="client.code" :placeholder="'Code'" />
              </b-form-group>
              <!-- name -->
              <validation-provider
                #default="{ errors }"
                :name="$t('Nombre')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Nombre')"
                  label-for="client-name"
                >
                  <b-form-input
                    v-model="client.name"
                    name="name"
                    :placeholder="$t('Nombre')"
                    :disabled="role === 'admin'"
                  />
                </b-form-group>
                <small
                  ref="errorField"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
              <!-- phone -->
              <validation-provider
                #default="{ errors }"
                :name="$t('Telefono')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Telefono')"
                  label-for="client-phone"
                >
                  <b-form-input
                    v-model="client.phone"
                    name="reference"
                    :placeholder="$t('Telefono')"
                  />
                </b-form-group>
                <small
                  ref="errorField"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
              <!-- address -->
              <validation-provider
                #default="{ errors }"
                :name="$t('Direccion')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Direccion')"
                  label-for="client-address"
                >
                  <b-form-input
                    v-model="client.address"
                    name="reference"
                    :placeholder="$t('Direccion')"
                  />
                </b-form-group>
                <small
                  ref="errorField"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
              <!-- municipio / localidad -->
              <validation-provider
                #default="{ errors }"
                :name="$t('location')"
                rules="required"
              >
                <b-form-group
                  :label="$t('location')"
                  label-for="client-city"
                >
                  <b-form-input
                    v-model="client.town"
                    name="reference"
                    :placeholder="$t('location')"
                  />
                </b-form-group>
                <small
                  ref="errorField"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
              <!-- referencia -->
              <!-- método de pago -->
              <b-form-group
                :label="$t('payment_method')"
                label-for="product-paymenttype"
              >
                <v-select
                  v-model="paymenttype"
                  :label="$t('payment_method')"
                  :options="paymentstypes"
                  :filterable="false"
                  :searchable="false"
                  :disabled="role === 'admin'"
                >
                  <template
                    slot="option"
                    slot-scope="option"
                  >
                    {{ option.name }}
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    {{ option.name }}
                  </template>
                </v-select>
              </b-form-group>
              <b-form-group
                :label="$t('Descripcion')"
                label-for="location-description"
              >
                <quill-editor v-model="client.description" />
              </b-form-group>
              <b-form-group
                v-if="role === 'super_admin'"
                class="mt-3"
              >
                <b-form-checkbox
                  v-model="active"
                  name="check-button-active"
                  switch
                  inline
                >
                  <span>Activar cliente</span>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group
                v-if="role === 'super_admin'"
                class="mt-3"
              >
                <b-form-checkbox
                  v-model="iva"
                  name="check-button-iva"
                  switch
                  inline
                >
                  <span>Cliente con IVA</span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <!-- CIF -->
              <validation-provider
                #default="{ errors }"
                :name="'CIF'"
                rules="required"
              >
                <b-form-group
                  :label="'CIF*'"
                  label-for="client-cif"
                >
                  <b-form-input
                    v-model="client.cif"
                    name="reference"
                    :placeholder="'CIF*'"
                    :disabled="role === 'admin'"
                  />
                </b-form-group>
                <small
                  ref="errorField"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
              <!-- correo -->
              <validation-provider
                #default="{ errors }"
                :name="$t('email')"
                rules="email|required"
              >
                <b-form-group
                  :label="$t('email')"
                  label-for="client-email"
                >
                  <b-form-input
                    v-model="client.email"
                    name="reference"
                    :placeholder="$t('email')"
                  />
                </b-form-group>
                <small
                  ref="errorField"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
              <!-- postal code -->
              <validation-provider
                #default="{ errors }"
                :name="$t('CodigoPostal')"
                rules="required"
              >
                <b-form-group
                  :label="$t('CodigoPostal')"
                  label-for="client-postalCode"
                >
                  <b-form-input
                    v-model="client.postalCode"
                    name="reference"
                    :placeholder="$t('CodigoPostal')"
                  />
                </b-form-group>
                <small
                  ref="errorField"
                  class="text-danger"
                >{{ errors[0] }}</small>
              </validation-provider>
              <b-form-group
                :label="$t('Pais')"
                label-for="register-country-client"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Pais')"
                  rules="required"
                >
                  <v-select
                    id="register-country-client"
                    :value="countrySelected"
                    :state="errors.length > 0 ? false : null"
                    :options="listCountries"
                    label="name"
                    :clearable="false"
                    name="register-country-client"
                    :placeholder="$t('Pais')"
                    @input="handlerClientCountry"
                  />
                  <small
                    ref="errorField"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- province client -->
              <b-form-group
                :label="$t('Provincia')"
                label-for="register-province-client"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Provincia')"
                  rules="required"
                >
                  <v-select
                    v-if="client.country && client.country.code === 'ES'"
                    v-model="client.province_object"
                    label="name"
                    :options="selectProvinces"
                    :filterable="true"
                    :searchable="true"
                    :placeholder="$t('Provincia')"
                  />
                  <b-form-input
                    v-else
                    v-model="client.province"
                    :placeholder="$t('Provincia')"
                    :disabled="!client.country"
                  />
                  <small
                    ref="errorField"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- web -->
              <b-form-group
                :label="'Web'"
                label-for="client-web"
              >
                <b-form-input
                  v-model="client.web"
                  name="reference"
                  :placeholder="'Web'"
                />
              </b-form-group>
              <!-- discount -->
              <b-form-group
                v-if="role === 'super_admin'"
                :label="'Porcentaje Descuento cortinas'"
                label-for="client-discount"
              >
                <b-form-input
                  v-model="client.percentage_discount"
                  name="percentage_discount"
                  :placeholder="'% Descuento'"
                />
              </b-form-group>
              <!-- tipo de empresa -->
              <b-form-group
                :label="$t('type_company')"
                label-for="register-tipo-empresa"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('type_company')"
                  rules="required"
                >
                  <v-select
                    v-model="client.type"
                    :options="selectTypeCompany"
                    :filterable="true"
                    :searchable="true"
                    :placeholder="$t('type_company')"
                    :disabled="role === 'admin'"
                  />
                  <small
                    ref="errorField"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              class="mt-2"
            >
              <b-form-group
                :label="$t('client_logo')"
                label-for="client-image"
              >
                <ImageDropzone
                  v-if="clientPrepare"
                  ref="image"
                  :files="files"
                  max-files="1"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12 text-right">
              <b-button
                type="submit"
                variant="primary"
                class="mt-2 mr-1"
              >
                {{ $t('Guardar') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ImageDropzone from '@/components/elements/ImageDropzone/ImageDropzone.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    vSelect,
    ImageDropzone,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      code: '',
      files: [],
      paymenttype: '',
      required,
      email,
      clientPrepare: false,
      active: false,
      iva: false,
      ClientProvince: '',
    }
  },
  computed: {
    ...mapGetters({
      countries: 'countries/getCountries',
      role: 'auth/getRole',
      client: 'clients/getClient',
      paymentstypes: 'payments/getSelectPayments',
      selectProvinces: 'provinces/getSelectProvinces',
      selectTypeCompany: 'config/getSelectTypeCompany',
      currentLanguage: 'languages/getCurrentLanguage',
    }),
    listCountries() {
      return this.countries.map(countrie => {
        countrie.name = countrie.title[this.currentLanguage]
        return countrie
      })
    },
    countrySelected() {
      return { ...this.client.country, name: this.client?.country?.title[this.currentLanguage] }
    },
  },
  methods: {
    ...mapActions({
      edit: 'clients/edit',
      getClient: 'clients/getClient',
      getSelectPayments: 'payments/selectPayments',
      getSelectProvinces: 'provinces/selectProvinces',
      getSelectTypeCompany: 'config/getSelectTypeCompany',
      getListCountries: 'countries/getListCountries',
    }),
    handleSubmit() {
      this.$refs.editClient.validate().then(success => {
        if (success) {
          const { id } = this.client
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, client: formData })
          }
        } else {
          this.$refs.errorField.scrollIntoView({
            behavior: 'smooth',
            block: 'end',
          })
        }
      })
    },
    setData() {
      if (this.client.image) {
        const file = {
          name: 'Image',
          url: this.client.image.path,
          urlDelete: `/clients/delete_image/${this.client.id}`,
        }
        this.files.push(file)
      }
      if (this.client.paymenttype) {
        this.paymenttype = this.client.paymenttype
      }
      if (this.client.active === 1 || this.client.active === true) {
        this.active = true
      }
      if (this.client.iva === 1 || this.client.iva === true) {
        this.iva = true
      }

      this.clientPrepare = true
    },
    createFormData() {
      const data = this.$refs.image.getFormData('image')
      data.append('code', this.client.code)
      data.append('name', this.client.name)
      data.append('description', this.client.description)
      data.append('cif', this.client.cif)
      data.append('address', this.client.address)
      data.append('postalCode', this.client.postalCode)
      data.append('country_id', this.client.country.id)
      data.append('city', this.client.city)
      data.append('phone', this.client.phone)
      if (this.client.email != '') data.append('email', this.client.email)
      if (this.client.web != '') data.append('web', this.client.web)
      data.append('province', this.client.country.code === 'ES' ? this.client.province_object.id : this.client.province)
      data.append('percentage_discount', this.client.percentage_discount)
      data.append('type', this.client.type)

      if (this.active) {
        data.append('active', 1)
      } else {
        data.append('active', 0)
      }
      if (this.iva) {
        data.append('iva', 1)
      } else {
        data.append('iva', 0)
      }

      if (this.paymenttype) data.append('paymenttype_id', this.paymenttype.id)
      else data.append('paymenttype_id', '')

      // return false

      return data
    },
    handlerClientCountry(value) {
      this.client.country = value
      this.client.province = ''
    },
  },
  async created() {
    await this.getClient(this.$route.params.id)
    await this.getSelectTypeCompany()
    await this.setData()
    await this.getListCountries({
      page: 1, per_page: 9999, search: '', orderBy: '',
    })
    await this.getSelectPayments({ page: 1, per_page: 999 })
    await this.getSelectProvinces({
      page: 1, per_page: 9999, search: '', orderBy: '',
    })
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
