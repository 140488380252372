var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center pt-50 mb-2"},[_c('h2',{staticClass:"font-weight-bolder m-0 hGoBack"},[_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-button',{staticClass:"btn-icon rounded-circle mr-50 text-danger bg-white",staticStyle:{"padding":"0.5px"},attrs:{"variant":"white"}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftCircleIcon","size":"35"}})],1)],1),_vm._v(" "+_vm._s(_vm.$t('cliente_file'))+" ")])]),_c('b-card',[_c('validation-observer',{ref:"editClient"},[_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":'Code',"label-for":"client-code"}},[_c('b-form-input',{attrs:{"placeholder":'Code'},model:{value:(_vm.client.code),callback:function ($$v) {_vm.$set(_vm.client, "code", $$v)},expression:"client.code"}})],1),_c('validation-provider',{attrs:{"name":_vm.$t('Nombre'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Nombre'),"label-for":"client-name"}},[_c('b-form-input',{attrs:{"name":"name","placeholder":_vm.$t('Nombre'),"disabled":_vm.role === 'admin'},model:{value:(_vm.client.name),callback:function ($$v) {_vm.$set(_vm.client, "name", $$v)},expression:"client.name"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('Telefono'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Telefono'),"label-for":"client-phone"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Telefono')},model:{value:(_vm.client.phone),callback:function ($$v) {_vm.$set(_vm.client, "phone", $$v)},expression:"client.phone"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('Direccion'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Direccion'),"label-for":"client-address"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('Direccion')},model:{value:(_vm.client.address),callback:function ($$v) {_vm.$set(_vm.client, "address", $$v)},expression:"client.address"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('location'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('location'),"label-for":"client-city"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('location')},model:{value:(_vm.client.town),callback:function ($$v) {_vm.$set(_vm.client, "town", $$v)},expression:"client.town"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-group',{attrs:{"label":_vm.$t('payment_method'),"label-for":"product-paymenttype"}},[_c('v-select',{attrs:{"label":_vm.$t('payment_method'),"options":_vm.paymentstypes,"filterable":false,"searchable":false,"disabled":_vm.role === 'admin'},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}]),model:{value:(_vm.paymenttype),callback:function ($$v) {_vm.paymenttype=$$v},expression:"paymenttype"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Descripcion'),"label-for":"location-description"}},[_c('quill-editor',{model:{value:(_vm.client.description),callback:function ($$v) {_vm.$set(_vm.client, "description", $$v)},expression:"client.description"}})],1),(_vm.role === 'super_admin')?_c('b-form-group',{staticClass:"mt-3"},[_c('b-form-checkbox',{attrs:{"name":"check-button-active","switch":"","inline":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('span',[_vm._v("Activar cliente")])])],1):_vm._e(),(_vm.role === 'super_admin')?_c('b-form-group',{staticClass:"mt-3"},[_c('b-form-checkbox',{attrs:{"name":"check-button-iva","switch":"","inline":""},model:{value:(_vm.iva),callback:function ($$v) {_vm.iva=$$v},expression:"iva"}},[_c('span',[_vm._v("Cliente con IVA")])])],1):_vm._e()],1),_c('b-col',{attrs:{"sm":"6"}},[_c('validation-provider',{attrs:{"name":'CIF',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":'CIF*',"label-for":"client-cif"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'CIF*',"disabled":_vm.role === 'admin'},model:{value:(_vm.client.cif),callback:function ($$v) {_vm.$set(_vm.client, "cif", $$v)},expression:"client.cif"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('email'),"rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('email'),"label-for":"client-email"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('email')},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":_vm.$t('CodigoPostal'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('CodigoPostal'),"label-for":"client-postalCode"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":_vm.$t('CodigoPostal')},model:{value:(_vm.client.postalCode),callback:function ($$v) {_vm.$set(_vm.client, "postalCode", $$v)},expression:"client.postalCode"}})],1),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-group',{attrs:{"label":_vm.$t('Pais'),"label-for":"register-country-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Pais'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"register-country-client","value":_vm.countrySelected,"state":errors.length > 0 ? false : null,"options":_vm.listCountries,"label":"name","clearable":false,"name":"register-country-client","placeholder":_vm.$t('Pais')},on:{"input":_vm.handlerClientCountry}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Provincia'),"label-for":"register-province-client"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Provincia'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.client.country && _vm.client.country.code === 'ES')?_c('v-select',{attrs:{"label":"name","options":_vm.selectProvinces,"filterable":true,"searchable":true,"placeholder":_vm.$t('Provincia')},model:{value:(_vm.client.province_object),callback:function ($$v) {_vm.$set(_vm.client, "province_object", $$v)},expression:"client.province_object"}}):_c('b-form-input',{attrs:{"placeholder":_vm.$t('Provincia'),"disabled":!_vm.client.country},model:{value:(_vm.client.province),callback:function ($$v) {_vm.$set(_vm.client, "province", $$v)},expression:"client.province"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":'Web',"label-for":"client-web"}},[_c('b-form-input',{attrs:{"name":"reference","placeholder":'Web'},model:{value:(_vm.client.web),callback:function ($$v) {_vm.$set(_vm.client, "web", $$v)},expression:"client.web"}})],1),(_vm.role === 'super_admin')?_c('b-form-group',{attrs:{"label":'Porcentaje Descuento cortinas',"label-for":"client-discount"}},[_c('b-form-input',{attrs:{"name":"percentage_discount","placeholder":'% Descuento'},model:{value:(_vm.client.percentage_discount),callback:function ($$v) {_vm.$set(_vm.client, "percentage_discount", $$v)},expression:"client.percentage_discount"}})],1):_vm._e(),_c('b-form-group',{attrs:{"label":_vm.$t('type_company'),"label-for":"register-tipo-empresa"}},[_c('validation-provider',{attrs:{"name":_vm.$t('type_company'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.selectTypeCompany,"filterable":true,"searchable":true,"placeholder":_vm.$t('type_company'),"disabled":_vm.role === 'admin'},model:{value:(_vm.client.type),callback:function ($$v) {_vm.$set(_vm.client, "type", $$v)},expression:"client.type"}}),_c('small',{ref:"errorField",staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('client_logo'),"label-for":"client-image"}},[(_vm.clientPrepare)?_c('ImageDropzone',{ref:"image",attrs:{"files":_vm.files,"max-files":"1"}}):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12 text-right"}},[_c('b-button',{staticClass:"mt-2 mr-1",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('Guardar'))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }